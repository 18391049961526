import { generatePages, getRoutesStandard, routesStandard } from '@/router-standard'
import { siteConfig } from './config'

export const routes = getRoutesStandard({
  siteConfig,
  pagesImport: import.meta.glob('./_pages/*.md', { eager: false }),
})

routes.push(...routesStandard())

routes.push(...generatePages({
  'buy-app-en': () => import(`./_pages/buy-en.md`),
  'buy-app-de': () => import(`./_pages/buy-de.md`),
}))

for (const lang of siteConfig.languages) {
  routes.push({
    path: `/${lang}`,
    meta: {
      pure: true,
      comp: () => import(`./_content/intro-${lang}.md`),
    },
    component: () => import('./app-homepage.vue'),
  })
}
