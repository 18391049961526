import type { SiteConfig } from '@/_types'

export const siteConfig: SiteConfig = {
  name: 'pdfify',
  host: 'pdfify.app',

  languages: ['en', 'de'],
  i18nLocalesModules: {
    en: () => import('../locales/en.json'),
    de: () => import('../locales/de.json'),
  },

  siteTitle: 'PDFify Mac App',
  baseUrl: 'https://pdfify.app',
  iconUrl: 'https://pdfify.app/logo.svg',

  // posts: () => import('./_posts/meta.json'),
  // postsPath: 'blog',
  productReleases: () => import('./_macos/releases.json'),

  plausibleId: 'pdfify.app',
  plausibleAppId: 'app.pdfify.app',

  repliesProductName: 'PDFify',

  supportEmail: 'support@pdfify.app',
}
